export default function ({ store, $axios, $cookies, req }, inject) {
    async function searchForCourses(options) {
        const searchData = {
            course: {
                search: {}
            }
        };
        if (options.documentation) searchData.course.documentation = true;
        // Set search parameters based on options
        if (options.set) searchData.course.search.set = options.set;
        if (options.page) searchData.course.search.page = parseInt(options.page, 10);
        if (options.size) searchData.course.search.size = parseInt(options.size, 10);
        if (options.query) searchData.course.search.query = options.query.toString();
        if (options.tags) {
            if (!Array.isArray(options.tags)) options.tags = [options.tags];
            searchData.course.search.filter = { tags: { included: options.tags } };
        }
        if (options.sort) {
            if (Array.isArray(options.sort)) {
                searchData.course.search.sort = options.sort;
            } else if (typeof options.sort === 'object') {
                console.log('options.sort as object', options.sort);
                searchData.course.search.sort = [options.sort];
                console.log('searchData.course.search.sort', searchData.course.search.sort);
            } else if (typeof options.sort === 'string') {
                searchData.course.search.sort = [{ [options.sort]: 'asc' }];
            }
        }
        if (options.fields) {
            if (!Array.isArray(options.fields)) options.fields = [options.fields];
            searchData.course.fields = options.fields;
        } else {
            searchData.course.fields = [
                "id",
                "title",
                {
                    "image_url": {
                        "art": "VThumb_300"
                    }
                }
            ];
        }

        return searchData;
    }

    async function processSearchResults(searchResults) {
        const $return = {};

        if (searchResults.course) {
            if (searchResults.course.objects && searchResults.course.objects.length) {
                $return.objects = searchResults.course.objects;
            }
            if (searchResults.course.summary) {
                $return.summary = searchResults.course.summary;
            }
        }

        return $return;
    }

    async function search(options) {
        if (!options.model) options.model = 'course';
        let searchData = {};

        if (options.model === 'course') {
            searchData = await searchForCourses(options);
        }

        try {
            const $searchResults = await store.dispatch('search/searchNexus', { searchData, $axios, $cookies });
            return await processSearchResults($searchResults);
        } catch (error) {
            return { error: error.message || 'An error occurred during the search' };
        }
    }

    async function setEducatorSet(set) {
        return await store.dispatch('search/setEducatorSet', { set, $cookies });
    }

    async function getEducatorSet() {
        return await store.dispatch('search/getEducatorSet', { $cookies });
    }

    inject('search', { search, setEducatorSet, getEducatorSet });
}