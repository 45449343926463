export const state = () => ({
  educators: {},
  auth_token: null
});

export const mutations = {
  setField(state, { id, field, value }) {
    if (!state.educators[id]) {
      state.educators[id] = {};
    }
    state.educators[id][field] = value;
    console.log(`Mutation setField: Set ${field} for educator ${id} to`, value);
  },
  updateEducator(state, { id, educator }) {
    if (!state.educators[id]) {
      state.educators[id] = {};
    }
    Object.assign(state.educators[id], educator);
    console.log(`Mutation updateEducator: Updated educator ${id} with`, educator);
  }
};

export const getters = {
  getField: (state) => (id, field) => {
    const educator = state.educators[id];
    //console.log(`Getter getField: Retrieved field ${field} for educator ${id}`, educator ? educator[field] : undefined);
    return educator && educator[field] ? educator[field] : undefined;
  },
  getEducator: (state) => (id) => {
    console.log(`Getter getEducator: Retrieved educator ${id}`, state.educators[id]);
    return state.educators[id] || {};
  }
};

function safeJSONParse(str) {
  try {
    return JSON.parse(str);
  } catch (error) {
    console.error('JSON parsing error:', error);
    return {};
  }
}

async function extractFieldsFromObject(fields, object) {
  const results = {};
  fields.forEach(field => {
    results[field] = object[field] !== undefined ? object[field] : null;
  });
  //console.log(`Function extractFieldsFromObject: Extracted fields`, results);
  return results;
}

export const actions = {
  async getFields({ commit, state }, { id, fields, $axios, $cookies }) {
    //console.log('Action getFields: Started', { id, fields });
    const fieldsArray = Array.isArray(fields) ? fields : [fields];

    // Check for data in cookies first
    let educator = state.educators[id] || {};
    const cookieData = $cookies.get(`educator-${id}-data`);
    if (cookieData) {
      const parsedCookieData = safeJSONParse(cookieData);
      //console.log('Action getFields: Parsed cookie data', parsedCookieData);
      educator = { ...educator, ...parsedCookieData };
      //console.log('Action getFields: Merged educator data with cookie data', educator);
      commit('updateEducator', { id, educator });
    }

    // Extract available fields from the merged educator data
    let extractedFields = await extractFieldsFromObject(fieldsArray, educator);
    //console.log('Action getFields: Extracted fields from cookie and state', extractedFields);

    // Check for missing fields
    const missingFields = fieldsArray.filter(field => !(field in extractedFields && extractedFields[field] != null));
    //console.log('Action getFields: Missing fields after checking cookie and state', missingFields);

    if (missingFields.length === 0) {
      //console.log('Action getFields: All fields found locally or in cookie', extractedFields);
      return typeof fields === 'string' ? extractedFields[fields] : extractedFields;
    }

    try {
      const response = await $axios.post(process.env.nexusUrl, { educator: { id, fields: missingFields, no_cache: true } });

      if (response.status === 200 && response.data.educator) {
        commit('updateEducator', { id, educator: response.data.educator });
        console.log('my fields array', fieldsArray)
        for(let i = 0; i < fieldsArray.length; i++)
          if(fieldsArray[i].includes(':')) fieldsArray[i] = fieldsArray[i].substring(0, fieldsArray[i].indexOf(':'))
        extractedFields = await extractFieldsFromObject(fieldsArray, response.data.educator);
        //console.log('Action getFields: Updated fields after fetch', extractedFields);
        $cookies.set(`educator-${id}-data`, JSON.stringify(state.educators[id]), {
          path: '/',
          domain: '.truefire.com',
          maxAge: 60 * 60 * 24 // 24 hours
        });

        //console.log('Action getFields: Updated fields after fetch', extractedFields);
        return typeof fields === 'string' ? extractedFields[fields] : extractedFields;
      } else {
        console.error('Action getFields: Non-200 status code received:', response.status);
      }
    } catch (error) {
      console.error('Action getFields: Error fetching data:', error);
    }
    return typeof fields === 'string' ? null : {};  // Return appropriate type based on fields input
  },

  async subscribeToEmails({ commit, state }, { fields, $axios, }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, fields);
      return response;
    } catch(err) {
      console.error('Error submitting email...', err);
    }
  }
};
