// store/member.js

import { ContentSteeringController, ErrorController } from "hls.js";

export const state = () => ({
  member: {},
  notifications_count: 0,
  player_configuration: {},
  pinForm: {},
  auth_token: null,
  skipRefreshOnLoad: []
});

export const mutations = {
  setField(state, { field, data }) {
    state.member[field] = data;
  },
  updateMember(state, member) {
    Object.assign(state.member, member);
  },
  setAuthToken(state, token) {
    state.auth_token = token;
  },
  setPinForm(state, config){
    state.pinForm = config
  },
  setPlayerConfiguration(state, playerConfiguration) {
    state.player_configuration = playerConfiguration;
  }
};

export const getters = {
  getField: (state) => (field) => {
    return state.member[field] ? { ...state.member[field], source: 'vuex' } : undefined;
  },
  getPlayerConfiguration: (state) => {
    return state.player_configuration;
  },
  getAuthToken(state) {
    return state.auth_token;
  },
  getPinForm(state){
    return state.pinForm
  }
};

function safeJSONParse(input) {
  if (typeof input === 'string') {
    try {
      return JSON.parse(input);
    } catch (error) {
      return null;
    }
  } else {
    return input;
  }
}

async function extractFieldsFromObject(fields, object) {
  let parsedObject = object;
  if (typeof object === 'string') {
    parsedObject = safeJSONParse(object);
    if (parsedObject === null) {
      return null;
    }
  }

  const results = {};
  fields.forEach(field => {
    results[field] = parsedObject[field] !== undefined ? parsedObject[field] : null;
  });
  return results;
}

export const actions = {

  async login({ commit, state }, { authentication, $axios, $cookies }) {

    let nexusObject = {
      member: {
        login: {
          username: authentication.username,
          password: authentication.password,
          remember_me: authentication.remember_me
        },
        fields: ['memberid']
      }
    };
    try{
      await $axios.post(process.env.nexusUrl, nexusObject);
      return false;
    }catch(error){
      console.error('Error during member login:', error);
      if (error.response && error.response.data && error.response.data.message) {
        return error.response.data.message;
      } 
      else if(error.message) return error.message;
      else return error;
    }

  },

  async register({ commit, state }, { registration, $axios, $cookies }) {
    //are we registering with credentials
    
    let joinObject = {
      username: registration.username,
      email: registration.email,
      password: registration.password,
      subscribe_to_educator: registration.subscribe_to_educator,
      subscribe_to_truefire: registration.subscribe_to_truefire
    };

    if(registration.bypass_trial !== undefined){
      joinObject.bypass_trial = registration.bypass_trial;
    }
    
    if($cookies.get('plus_network_id')){
      joinObject.plus_network_id = $cookies.get('plus_network_id');
    }
    
    let nexusObject = {
      member: {
        join: joinObject,
        fields: ['memberid']
      }
    };
    if (registration.email_opted_in) nexusObject.member.join.email_opted_in = true;

    try{
      await $axios.post(process.env.nexusUrl, nexusObject);
      return false;
    }catch(error){
      console.error('Error registering member:', error);
      if (error.response && error.response.data && error.response.data.message) {
        return error.response.data.message;
      } 
      else if (error.message) return error.message;
      else return error;
    }
  },

  async getFields({ commit, state }, { fields, $axios, $cookies }) {
    const authToken = $cookies.get('auth_token');
    if (!authToken) {
      return {};
    }
    commit('setAuthToken', authToken);

    let fieldsArray = Array.isArray(fields) ? fields : [fields];
    let storedObject = state.member;

    // const cookieData = $cookies.get('member-data');
    // if (cookieData) {
    //   storedObject = safeJSONParse(cookieData) || {};
    //   commit('updateMember', storedObject);
    // }

    let extractedFields = await extractFieldsFromObject(fieldsArray, storedObject);
    let missingFields = fieldsArray.filter(field => !(field in extractedFields && extractedFields[field] != null));

    if (missingFields.length === 0) {
      return Array.isArray(fields) ? extractedFields : extractedFields[fields];
    }

    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { fields: missingFields }
      });

      if (response.status === 200 && response.data.member) {
        commit('updateMember', response.data.member);
        extractedFields = await extractFieldsFromObject(fieldsArray, response.data.member);

        // $cookies.set('member-data', JSON.stringify(state.member), {
        //   path: '/',
        //   domain: '.truefire.com',
        //   maxAge: 60 * 60 * 24 // 24 hours
        // });
        return Array.isArray(fields) ? extractedFields : extractedFields[fields];
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  },
  // Notifications
  async getNotificationsCount({ commit, state }, { $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { fields: ['unread_notification_count'] }
      });
      const count = response.data.member.unread_notification_count;
      return count;
    } catch (error) {
      return 0;
    }
  },
  async getNotifications({ commit, state }, { $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { fields: ['notifications'] }
      });
      return response.data.member.notifications;
    } catch (error) {
      console.error('Error fetching notifications:', error);
      return [];
    }
  },
  async markNotificationsAsRead({ commit, state }, { notificationIds, $axios, $cookies }) {
    if (!notificationIds) {
      notificationIds = [];
    }
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { mark_notifications_as_read: notificationIds }
      });
      return {};
    } catch (error) {
      console.error('Error marking notifications as read:', error);
      return {};
    }
  },
  // Collections
  async getCollections({ commit, state }, { $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,fields: ['collections'] }
      });
      return response.data.member.collections;
    } catch (error) {
      console.error('Error fetching collections:', error);
      return [];
    }
  },
  async addCollection({ commit, state }, { name, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,add_collection: name, fields: ['collections'] }
      });
      return true;
    } catch (error) {
      console.error('Error adding collection:', error);
      return false;
    }
  },
  async addCourseToCollection({ commit, state }, { collectionId, courseId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,add_course_to_collection: { collection_id: collectionId, course_id: courseId }, fields: [
          'collections',
          {
            'collections_for_item': {
              'collections_for_item': courseId
            }
          }
        ] }
      });
      return true;
    } catch (error) {
      console.error('Error adding course to collection:', error);
      return false;
    }
  },
  async removeCourseFromCollection({ commit, state }, { collectionId, courseId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,remove_course_from_collection: { collection_id: collectionId, course_id: courseId }, fields: ['collections'] }
      });
      return true;
    } catch (error) {
      console.error('Error removing course from collection:', error);
      return false;
    }
  },
  async removeCollection({ commit, state }, { collectionId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,remove_collection: collectionId, fields: ['collections'] }
      });
      return true;
    } catch (error) {
      console.error('Error removing collection:', error);
      return false;
    }
  },
  async getCollectionsForItem({ commit, state }, { itemId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: {
          "no_cache": true,
          fields: [
            {
              'collections_for_item': {
                'collections_for_item': itemId
              }
            }]
        }
      });
      return response.data.member.collections_for_item;
    } catch (error) {
      console.error('Error fetching collections for item:', error);
      return [];
    }
  },
  async getCollection({ commit, state }, { collectionId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,fields: {collection:{collection:collectionId}}}
      });
      return response.data.member.collection;
    } catch (error) {
      console.error('Error fetching collection:', error);
      return [];
    }
  },
  async archiveItem({ commit, state }, { itemId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,archive_collection_item: itemId, fields: ['collections'] }
      });
      return true;
    } catch (error) {
      console.error('Error archiving item:', error);
      return false;
    }
  },
  async removeArchivedItem({ commit, state }, { itemId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,remove_archived_collection_item: itemId, fields: ['collections'] }
      });
      return true;
    } catch (error) {
      console.error('Error removing archived item:', error);
      return false;
    }
  },
  async getAvailableCollectionsForItem({ commit, state }, { itemId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,fields: [{'available_collections_for_item':{'available_collections_for_item':itemId}}] }
      });
      return response.data.member.available_collections_for_item;
    } catch (error) {
      console.error('Error fetching available collections for item:', error);
      return [];
    }
  },
  async addCourseToFavorites({ commit, state }, { courseId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,add_favorite_course: courseId, fields: ['memberid'] }
      });
      return true;
    } catch (error) {
      console.error('Error adding course to favorites:', error);
      return false;
    }
  },
  async removeCourseFromFavorites({ commit, state }, { courseId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,remove_favorite_course: courseId, fields: ['memberid'] }
      });
      return true;
    } catch (error) {
      console.error('Error removing course from favorites:', error);
      return false;
    }
  },
  async addEducatorToFavorites({ commit, state }, { educatorId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,add_favorite_educator: educatorId, fields: ['memberid'] }
      });
      return true;
    } catch (error) {
      console.error('Error adding educator to favorites:', error);
      return false;
    }
  },
  async removeEducatorFromFavorites({ commit, state }, { educatorId, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,remove_favorite_educator: educatorId, fields: ['memberid'] }
      });
      return true;
    } catch (error) {
      console.error('Error removing educator from favorites:', error);
      return false;
    }
  },

  async getMemberFromPartnershipNonce({ commit, state }, { nonce, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,from_nonce:{nonce:nonce}, fields: ['player_configuration'] }
      });
      if(typeof response.data.member.player_configuration !== 'undefined'){
        $cookies.set('player_config', Buffer.from(JSON.stringify(response.data.member.player_configuration)).toString('base64'))
        commit('setPlayerConfiguration', response.data.member.player_configuration);
      }
    } catch (error) {
      console.error('Unable to get member from nonce:', error);
      return false;
    }
  },

  async resendPinEmailFromFormKey({ commit, state }, { formKey, $axios, $cookies }) {
    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,resend_pin_email_from_key:{key:formKey} }
      });
      return true;
    } catch (error) {
      console.error('Unable to resend pin email from form key:', error);
      return false;
    }
  },

  async getMemberFromPinAndFormKey({ commit, state }, { pin, formKey, $axios, $cookies }) {
    try {
      console.log('my pin for post is', pin)
      console.log('my key for post is', formKey)
      const response = await $axios.post(process.env.nexusUrl, {
        member: { "no_cache": true,from_pin_and_key:{pin:pin,key:formKey}, fields: ['player_configuration'] }
      });
      if(typeof response.data.member.player_configuration !== 'undefined'){
        $cookies.set('player_config', Buffer.from(JSON.stringify(response.data.member.player_configuration)).toString('base64'))
        commit('setPlayerConfiguration', response.data.member.player_configuration);
      }
    } catch (error) {
      console.error('Unable to get member from pin and key:', error);
      return false;
    }
  },

  async getMemberData({ commit, state, $axios, $cookies }, { fields }) {
    const authToken = $cookies.get('auth_token');
    if (!authToken) {
      console.warn('No auth token found, skipping Axios call');
      return;
    }

    try {
      const response = await $axios.post(process.env.nexusUrl, {
        member: { fields }
      });

      if (response.status === 200 && response.data.member) {
        commit('updateMember', response.data.member);
        $cookies.set('member-data', JSON.stringify(state.member), {
          path: '/',
          domain: '.truefire.com',
          maxAge: 60 * 60 * 24 // 24 hours
        });
      } else {
        console.error('Non-200 status code received:', response.status);
      }
    } catch (error) {
      console.error('Error fetching member data:', error);
    }
  },

  async checkPlayerConfiguration({commit, state}, {$cookies}){
    if($cookies.get('player_config')){
      try {
        commit('setPlayerConfiguration', JSON.parse(Buffer.from($cookies.get('player_config'), 'base64').toString()))
      } catch(error){
        console.log('error getting player config')
      }
    }
  },

  updateSkipRefreshOnLoad({ commit }, skipRefreshOnLoad) {
    commit('updateSkipRefreshOnLoad', skipRefreshOnLoad);
  }
}