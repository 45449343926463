export const state = () => ({
    course: {}
  });
  
export const mutations = {
    set(state, result) {
        state.course = result;
    }
};
  
export const getters = {};


export const actions = {
    async getCourse({ commit, state }, { itemId, options, $axios, $cookies }) {
        const postObj = {
            course: {
                id: itemId,
                no_cache: true,
                fields: options
            }
        }
        
        try {
            const response = await $axios.post(process.env.nexusUrl, postObj);
            if (response.status == 200 && response.data && response.data.course && Object.keys(response.data.course)) {
                return response.data.course;
            } else {
                throw Error;
            }
        }
        catch (e) {
            console.log("Error getting course:", e);
        }
        return {};
    },
    async getRecommendations({ commit, state }, { options, $axios, $cookies }) {
        const postObj = {
            course: {
                set: "recommendations",
                fields: options
            }
        }
        try {
            const response = await $axios.post(process.env.nexusUrl, postObj);
            if (response.status == 200 && response.data && response.data.course && Object.keys(response.data.course)) {
                return response.data.course;
            } else {
                throw Error;
            }
        }
        catch (e) {
            console.log("Error getting recommended courses:", e);
        }
        return {};
    },
    async getRecommendationsByCourseID({ commit, state }, { courseID, options, $axios, $cookies }) {
        const setValue = `recommendations_by_course:` + courseID;
        const postObj = {
            course: {
                set: setValue,
                limit: 4,
                fields: options
            }
        }
        try {
            const response = await $axios.post(process.env.nexusUrl, postObj);
            if (response.status == 200 && response.data && response.data.course && Object.keys(response.data.course)) {
                return response.data.course;
            } else {
                throw Error;
            }
        }
        catch (e) {
            console.log("Error getting recommendations by course ID:", e);
        }
        return {};
    }
}