export default function ({ store, $axios, $cookies, req }, inject) {
    
    async function get(id) {
        return await store.dispatch('configs/fetchConfigById', { id, $axios, $cookies });
    }

    async function nav(type) {
        return await store.dispatch('configs/getNav', { type, $axios, $cookies });
    }

    async function plusNetwork() {
        return await store.dispatch('configs/getPlusNetwork', { $axios, $cookies });
    }

    inject('configs', { get, nav, plusNetwork });
}
